import parse_query_string from 'parse_query_string';
let qs = parse_query_string(window.location.search.substring(1));

export default function(affiliate_params) {
    let siteAffId = 'erogames';

    if (qs['aff']) {
        Sexcash.hit(siteAffId, 'erogescookie', 'aff', null, false)
    }  else {
        let aff = process.env.SEXCASH_AFF_ID;
        let affOverride = document.querySelector('body').getAttribute('data-force-aff');

        if (affOverride) {
            aff = affOverride;
        } else {
            affiliate_params.forEach(function (param, index) {
                if (qs[param[0]]) {
                    // OUR-AFF-ID_TRACKER1_TRACKER2_VISITOR-ID.
                    aff = process.env.SEXCASH_AFF_ID + "_" + param[0] + "__" + qs[param[0]];
                }
            });
        }

        Sexcash.hit(siteAffId, 'erogescookie', 'aff', null, false, aff)
    }
}
