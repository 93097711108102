import Cookies from 'js-cookie';
import tracking_sexcash from 'tracking_sexcash';
import parse_query_string from 'parse_query_string';

export default class Disclaimer {
  static #MAX_COOKIE_TIME = 365;

  constructor({show, hide} = {}) {
    this.show = show;
    this.hide = hide;
  }

  init() {
    let disclaimer = Cookies.get('disclaimer');
    let qs = parse_query_string(window.location.search.substring(1)) || [];

    // To avoid `this` confusion
    var hide = this.hide;

    if (disclaimer && qs && qs['disclaimer'] === 'show') {
      disclaimer = undefined;
    }

    if (disclaimer && qs && qs['disclaimer'] === 'always') {
      Cookies.remove('disclaimer');
      disclaimer = undefined;
    }

    if (!disclaimer) {
      if ((document.querySelector('body').getAttribute('data-disclaimer') === 'none') ||
        (qs && qs['disclaimer'] === 'none')) {
        Cookies.set('disclaimer', 'none', {expires: Disclaimer.#MAX_COOKIE_TIME});
      } else {
        this.show();

        document.addEventListener('click', function (event) {
          let link = document.querySelector('.button-disclaimer');
          let parent = event.target.parentNode

          if (event.target !== link && parent !== link) return;

          Cookies.set(
            'disclaimer', 'none', {expires: Disclaimer.#MAX_COOKIE_TIME}
          );

          if (link.getAttribute('href') == '#') {
            hide();
          }
        });
      }
    }

    // Ensure browser language cookie is set
    if (!Cookies.get('browser_language')) {
      let language = navigator.languages && navigator.languages[0] ||
          navigator.language ||
          navigator.userLanguage;
      if (language) {
        Cookies.set(
            'browser_language',
            language,
            {expires: Disclaimer.#MAX_COOKIE_TIME}
        );
      }
    }

    if (Cookies.get('visited')) {
      ['tc1', 'tc2', 'tc3'].forEach(function(tc_param) {
        if (qs[tc_param]) {
          let cookie_name = 'shadow_' + tc_param;
          Cookies.set(cookie_name, qs[tc_param], {expires: Disclaimer.#MAX_COOKIE_TIME});
        }
      });

      return;
    }

    const affiliate_params = [
      ['crakid', 'crakid'],
      ['exs2s', 'exs2s'],
      ['jus2s', 'jus2s'],
      ['tstars_s2s', 'tstars_s2s']
    ]
    const large_cookie_params = []
    // control params from query string
    const array = affiliate_params.concat(
        [
          ['tc1', 'tc1'],
          ['tc2', 'tc2'],
          ['tc3', 'tc3'],
          ['tf_cpa_token', 'tfy_cpa_token'],
          ['click_hash', 'click_hash'],
          ['ocode', 'ocode']
        ]
    )
    const non_native_domain = document.querySelector('body').getAttribute('data-non-native-domain') === 'yes'
    array.forEach(function (param, index) {
      if (qs[param[0]]) {
        let is_small_param = large_cookie_params.indexOf(param[0]) === -1

        if (is_small_param || non_native_domain) {
          Cookies.set(param[1], qs[param[0]], {expires: Disclaimer.#MAX_COOKIE_TIME});
        }
      }
    });

    let referrer = document.referrer.substring(0, 255);
    if (referrer) { Cookies.set('external_referer', referrer, {expires: Disclaimer.#MAX_COOKIE_TIME}); }

    if (process.env.SEXCASH_TRACKER === "true") {
      tracking_sexcash(affiliate_params);
    }

    Cookies.set('visited', 'true', {expires: Disclaimer.#MAX_COOKIE_TIME});
  }
}
